/*
 * @Description:教室管理
 * @Author: 琢磨先生
 * @Date: 2024-07-15 12:27:23
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-11 22:18:06
 */
export default {
	path: '/',
	icon: '',
	meta: {
		title: '教务ERP'
	},
	children: [
		{
			path: '/',
			icon: '',
			meta: {
				title: '图书管理'
			},
			children: [
				{
					path: '/erp/books/bookManage',
					icon: '',
					component: () => import('@/views/erp/books/bookManage'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '图书'
					}
				},
				{
					path: '/erp/books/bookClassification',
					icon: '',
					component: () => import('@/views/erp/books/bookClassification'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '图书类目'
					}
				},
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '印刷制作'
			},
			children: [
				{
					path: '/erp/print/printLog',
					icon: '',
					component: () => import('@/views/erp/print/printLog'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '印刷记录'
					}
				},
				{
					path: '/erp/print/printPlant',
					icon: '',
					component: () => import('@/views/erp/print/printPlant'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '印制厂'
					}
				},
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '契约锁管理'
			},
			children: [
				{
					path: '/erp/print/contractLock',
					icon: '',
					component: () => import('@/views/erp/contractLock/lockTemplate/index.vue'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '契约模板'
					}
				},
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '酒店管理'
			},
			children: [
				{
					path: '/erp/hotel/note',
					icon: '',
					component: () => import('@/views/erp/hotel/note'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '酒店使用记录'
					}
				},
				{
					path: '/erp/hotel/store',
					icon: '',
					component: () => import('@/views/erp/hotel/store'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '酒店库'
					}
				},
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '学员管理'
			},
			children: [
				{
					path: '/erp/student/registration',
					icon: '',
					component: () => import('@/views/erp/student/registration'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '报名表'
					}
				},
				{
					path: '/erp/student/registrationLog',
					icon: '',
					component: () => import('@/views/erp/student/registrationLog'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '报名记录'
					}
				},
				{
					path: '/erp/student/modify',
					icon: '',
					component: () => import('@/views/erp/student/modify'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '完善资料'
					}
				},
				{
					path: '/erp/student',
					icon: '',
					component: () => import('@/views/erp/student'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '正式学员'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '讲义管理'
			},
			children: [
				{
					path: '/erp/notes/recordList',
					icon: '',
					component: () => import('@/views/erp/notes/recordList'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '讲义记录'
					}
				},
				{
					path: '/erp/notes/newAddRecord',
					icon: '',
					component: () => import('@/views/erp/notes/newAddRecord'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '新申请讲义'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '排课管理'
			},
			children: [
				{
					path: '/erp/coursePlanner/newApp',
					icon: '',
					component: () => import('@/views/erp/coursePlanner/newApp'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '新申请'
					}
				},
				{
					path: '/erp/coursePlanner/plan',
					icon: '',
					component: () => import('@/views/erp/coursePlanner/plan'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '排课'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '班级管理'
			},
			children: [
				{
					path: '/erp/class',
					icon: '',
					component: () => import('@/views/erp/class'),
					meta: {
						title: '班级'
					},
					power: 'admin/v1/kq/plan'
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '场地管理'
			},
			children: [
				{
					path: '/erp/classroom',
					icon: '',
					component: () => import('@/views/erp/classroom'),
					meta: {
						title: '教室'
					},
					power: 'admin/v1/kq/plan'
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '课程管理'
			},
			children: [
				{
					path: '/erp/kc/gov',
					icon: '',
					component: () => import('@/views/erp/kc/gov'),
					meta: {
						title: '公职类型'
					},
					power: 'admin/v1/kq/plan'
				},
				{
					path: '/erp/kc/type',
					icon: '',
					component: () => import('@/views/erp/kc/type'),
					meta: {
						title: '课程分类'
					},
					power: 'admin/v1/kq/plan'
				},
				{
					path: '/erp/kc/curriculum',
					icon: '',
					component: () => import('@/views/erp/kc/curriculum'),
					meta: {
						title: '课程'
					},
					power: 'admin/v1/kq/plan'
				},
				{
					path: '/erp/kc/coupon',
					icon: '',
					component: () => import('@/views/erp/kc/coupon'),
					meta: {
						title: '优惠券'
					},
					power: 'admin/v1/kq/plan'
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '收付款管理'
			},
			children: [
				// {
				// 	path: '/erp/money/audits',
				// 	icon: '',
				// 	component: () => import('@/views/erp/money/audits'),
				// 	power: 'admin/v1/kq/plan',
				// 	meta: {
				// 		title: '缴费待核'
				// 	}
				// },
				// {
				// 	path: '/erp/money/auditsTrail',
				// 	icon: '',
				// 	component: () => import('@/views/erp/money/auditsTrail'),
				// 	power: 'admin/v1/kq/plan',
				// 	meta: {
				// 		title: '缴费审核记录'
				// 	}
				// },
				{
					path: '/erp/money/receipt',
					icon: '',
					component: () => import('@/views/erp/money/receipt'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '收款记录'
					}
				},
				{
					path: '/erp/money/refund',
					icon: '',
					component: () => import('@/views/erp/money/refund'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '退费记录'
					}
				},
				{
					path: '/erp/money/reason',
					icon: '',
					component: () => import('@/views/erp/money/reason'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '退费原因'
					}
				}
			]
		}
	]
}
