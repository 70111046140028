<template>
	<el-cascader
		:props="props"
		v-model="model"
		clearable
		:persistent="flage"
		@visible-change="onChange"
	/>
</template>

<script setup>
import { apiGetWmsWarehouseAreaList, apiGetAllWmsWarehouseList } from '@/api/public'
import { onMounted, ref, watch, nextTick } from 'vue'

const model = ref(null)
const flage = ref(true)
const callback = ref(null)
const props = ref({
	lazy: true,
	emitPath: false,
	lazyLoad(node, resolve) {
		const { level, data } = node
		callback.value = resolve
		apiGetWmsWarehouseAreaList({
			warehouse_area_id: data.value
		}).then((res) => {
			let nodes = res.data.map((item) => ({
				value: item,
				label: item.name,
				leaf: true
			}))
			resolve(nodes)
		})
	}
})

watch(
	() => model.value,
	(val) => {
		console.log('🚀 ~ watch ~ val:', val)
	}
)

const nodes_ = ref([])
onMounted(() => {
	apiGetAllWmsWarehouseList().then((res) => {
		let nodes = res.data.map((item) => ({
			value: item,
			label: item.name,
			leaf: false
		}))
		nodes_.value = nodes
		callback.value(nodes)
	})
})

const onChange = () => {
	callback.value(nodes_.value)
}
</script>
