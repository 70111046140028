/*
 * @Author: Sky
 * @Date: 2024-09-23 09:19:22
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-23 09:19:34
 * @Description: desc
 */
export default {
  path: '/',
  icon: '',
  component: '',
  meta: {
    title: 'OA办公'
  },
  children: [
    {
      path: '/',
      icon: 'School',
      component: '',
      meta: {
        title: '学校管理'
      },
      children: [
        {
          path: '/oa/school',
          icon: '',
          component: () => import('@/views/oa/school/school'),
          power: 'admin/v1/day',
          meta: {
            title: '学校'
          }
        },
      ]
    },
    {
      path: '/',
      icon: 'user',
      component: '',
      meta: {
        title: '日报周报管理'
      },
      children: [
        {
          path: '/oa/day',
          icon: '',
          component: () => import('@/views/oa/report/day'),
          power: 'admin/v1/day',
          meta: {
            title: '日报'
          }
        },
        {
          path: '/oa/week',
          icon: '',
          component: () => import('@/views/oa/report/week'),
          power: 'admin/v1/week',
          meta: {
            title: '周报'
          }
        },
        {
          path: '/oa/month',
          icon: '',
          component: () => import('@/views/oa/report/month'),
          power: 'admin/v1/month',
          meta: {
            title: '月报'
          }
        },
      ]
    },
    {
      path: '/',
      icon: 'user',
      component: '',
      meta: {
        title: '人事管理'
      },
      children: [
        {
          path: '/oa/emp',
          icon: '',
          component: () => import('@/views/oa/person/emp'),
          power: 'admin/v1/emp',
          meta: {
            title: '人员档案'
          }
        },
        {
          path: '/oa/emp/rank',
          icon: '',
          component: () => import('@/views/oa/person/rank'),
          power: 'admin/v1/rank',
          meta: {
            title: '职级管理'
          }
        },
        {
          path: '/oa/emp/dept',
          icon: '',
          component: () => import('@/views/oa/person/dept'),
          power: 'admin/v1/dept',
          meta: {
            title: '部门管理'
          }
        },
        {
          path: '/oa/emp/post',
          icon: '',
          component: () => import('@/views/oa/person/post'),
          power: 'admin/v1/post',
          meta: {
            title: '岗位管理'
          }
        },
        {
          path: '/oa/emp/job',
          icon: '',
          component: () => import('@/views/oa/person/job'),
          power: 'admin/v1/job',
          meta: {
            title: '职位管理'
          }
        }
      ]
    },

    {
      path: '/',
      icon: 'user',
      component: '',
      meta: {
        title: '考勤管理'
      },
      children: [
        {
          path: '/oa/kq/leave',
          icon: '',
          component: () => import('@/views/oa/kq/leave'),
          power: 'admin/v1/kq/leave',
          meta: {
            title: '请假记录'
          }
        },
        {
          path: '/oa/kq/leaveType',
          icon: '',
          component: () => import('@/views/oa/kq/leaveType'),
          power: 'admin/v1/kq/leaveType',
          meta: {
            title: '请假类型'
          }
        },
        {
          path: '/oa/kq/plain',
          icon: '',
          component: () => import('@/views/oa/kq/plain'),
          power: 'admin/v1/kq/plain',
          meta: {
            title: '排班管理'
          }
        },
        {
          path: '/oa/kq/class',
          icon: '',
          component: () => import('@/views/oa/kq/class'),
          power: 'admin/v1/kq/class',
          meta: {
            title: '班次管理'
          }
        },
        {
          path: '/oa/kq/result',
          icon: '',
          component: () => import('@/views/oa/kq/result'),
          power: 'admin/v1/kq/result',
          meta: {
            title: '考勤结果'
          }
        },
        {
          path: '/oa/kq/resultGroup',
          icon: '',
          component: () => import('@/views/oa/kq/resultGroup'),
          power: 'admin/v1/kq/resultGroup',
          meta: {
            title: '考勤组管理'
          }
        }
      ]
    },
    {
      path: '/',
      icon: 'Notification',
      component: '',
      meta: {
        title: '申请审批单'
      },
      children: [
        {
          path: '/oa/approve/askoff',
          icon: '',
          component: () => import('@/views/oa/approve/askoff'),
          power: 'admin/v1/kq/plan',
          meta: {
            title: '请假申请'
          }
        },
        {
          path: '/oa/approve/worker',
          icon: '',
          component: () => import('@/views/oa/approve/worker'),
          power: 'admin/v1/kq/plan',
          meta: {
            title: '转正申请'
          }
        },
        {
          path: '/oa/approve/entry',
          icon: '',
          component: () => import('@/views/oa/approve/entry'),
          power: 'admin/v1/kq/plan',
          meta: {
            title: '入职申请'
          }
        },
        {
          path: '/oa/approve/leave',
          icon: '',
          component: () => import('@/views/oa/approve/leave'),
          power: 'admin/v1/kq/plan',
          meta: { title: '离职申请' }
        },
        {
          path: '/oa/approve/salary',
          icon: '',
          component: () => import('@/views/oa/approve/salary'),
          power: 'admin/v1/kq/plan',
          meta: { title: '调薪申请' }
        }
      ]
    },
    {
      path: '/',
      icon: 'Notification',
      component: '',
      meta: {
        title: '会议室管理'
      },
      children: [
        {
          path: '/oa/meeting/synopsis',
          icon: '',
          component: () => import('@/views/oa/meeting/synopsis'),
          power: 'admin/v1/kq/plan',
          meta: { title: '会议纪要' }
        },
        {
          path: '/oa/meeting/schedule',
          icon: '',
          component: () => import('@/views/oa/meeting/schedule'),
          power: 'admin/v1/kq/plan',
          meta: { title: '会议室预定' }
        },
        {
          path: '/oa/meeting/appliances',
          icon: '',
          component: () => import('@/views/oa/meeting/appliances'),
          power: 'admin/v1/kq/plan',
          meta: { title: '会议室设施' }
        },
        {
          path: '/oa/meeting/meetingRooms',
          icon: '',
          component: () => import('@/views/oa/meeting/meetingRooms'),
          power: 'admin/v1/kq/plan',
          meta: { title: '会议室' }
        },
        {
          path: '/oa/meeting/record',
          icon: '',
          component: () => import('@/views/oa/meeting/record'),
          power: 'admin/v1/kq/plan',
          meta: { title: '会议管理' }
        },
        {
          path: '/oa/meeting/today',
          icon: '',
          component: () => import('@/views/oa/meeting/today'),
          power: 'admin/v1/kq/plan',
          meta: { title: '今日会议' }
        }
      ]
    },
    {
      path: '/',
      icon: 'Notification',
      component: '',
      meta: {
        title: '督办中心'
      },
      children: [
        {
          path: '/oa/task/await',
          icon: '',
          component: () => import('@/views/oa/task/await'),
          power: 'admin/v1/kq/plan',
          meta: { title: '待办' }
        },
        {
          path: '/oa/task/process',
          icon: '',
          component: () => import('@/views/oa/task/process'),
          power: 'admin/v1/kq/plan',
          meta: { title: '办理中' }
        },
        {
          path: '/oa/task/record',
          icon: '',
          component: () => import('@/views/oa/task/record'),
          power: 'admin/v1/kq/plan',
          meta: { title: '全部记录' }
        },
        {
          path: '/oa/task/type',
          icon: '',
          component: () => import('@/views/oa/task/type'),
          power: 'admin/v1/kq/plan',
          meta: { title: '任务类型' }
        },
        {
          path: '/oa/task/tag',
          icon: '',
          component: () => import('@/views/oa/task/tag'),
          power: 'admin/v1/kq/plan',
          meta: { title: '任务标签' }
        }
      ]
    },
    {
      path: '/',
      icon: 'Notification',
      component: '',
      meta: {
        title: '招聘管理'
      },
      children: [
        {
          path: '/oa/recruitment/channel',
          icon: '',
          component: () => import('@/views/oa/recruitment/channel'),
          power: 'admin/v1/kq/plan',
          meta: { title: '招聘渠道' }
        },
        {
          path: '/oa/recruitment/candidates',
          icon: '',
          component: () => import('@/views/oa/recruitment/candidates'),
          power: 'admin/v1/kq/plan',
          meta: { title: '面试安排' }
        },
        {
          path: '/oa/recruitment/interviews',
          icon: '',
          component: () => import('@/views/oa/recruitment/interviews'),
          power: 'admin/v1/kq/plan',
          meta: { title: '候选人' }
        },
        {
          path: '/oa/recruitment/demand',
          icon: '',
          component: () => import('@/views/oa/recruitment/demand'),
          power: 'admin/v1/kq/plan',
          meta: { title: '招聘需求' }
        }
      ]
    },
    {
      path: '/',
      icon: 'Notification',
      component: '',
      meta: {
        title: '公告管理'
      },
      children: [
        {
          path: '/oa/announcement/newannouncement',
          icon: '',
          component: () => import('@/views/oa/announcement/newannouncement'),
          power: 'admin/v1/kq/plan',
          meta: { title: '新公告' }
        },
        {
          path: '/oa/announcement/tobereleased',
          icon: '',
          component: () => import('@/views/oa/announcement/tobereleased'),
          power: 'admin/v1/kq/plan',
          meta: { title: '待发布' }
        },
        {
          path: '/oa/announcement/notice',
          icon: '',
          component: () => import('@/views/oa/announcement/notice'),
          power: 'admin/v1/kq/plan',
          meta: { title: '公告' }
        },
        {
          path: '/oa/announcement/announcementtype',
          icon: '',
          component: () => import('@/views/oa/announcement/announcementtype'),
          power: 'admin/v1/kq/plan',
          meta: { title: '公告类型' }
        }
      ]
    }
  ]
}
