/*
 * @Description: 菜单配置说明  父级的 path = / , icon 请查看element plus的icon文档
 * @Author: 琢磨先生
 * @Date: 2024-07-12 10:03:09
 * @LastEditors: GW 1519964028@.qq.com
 * @LastEditTime: 2024-09-24 17:17:30
 */

import mywork from './menu_mywork'
import settings from './menu_settings'
import oa from './menu_oa'
import crm from './menu_crm'
import erp from './menu_erp'
import process from './menu_process'
import finance from './menu_finance'
import fixedAssets from './menu_fixedAssets'
import wmg from './menu_wmg'

var menus = [
	{
		path: '/',
		icon: '',
		ignore: true, //忽略权限校验
		meta: {
			title: '仪表盘'
		},
		hideSide: true,//隐藏左侧菜单
		children: [
			{
				path: '/home',
				icon: '',
				ignore: true, //忽略权限校验
				component: () => import('@/views/home'),
				meta: {
					title: '仪表盘'
				}
			}
		]
	},
	mywork,
	oa,
	crm,
	erp,
	finance,
	process,
	fixedAssets,
	settings,
	wmg
]

//递归菜单处理每级菜单的url集合和菜单项id
var i = 0
menus.forEach((item) => {
	item.urls = []
	recursion(item, null, i++)
})

function recursion(item, father, i) {
	if (father) {
		item.id = `${father.id}-${i}`
	} else {
		item.id = '' + i
	}
	if (item.path && item.path != '/') {
		if (father) {
			father.urls.push(item.path)
		} else {
			item.urls = [item.path]
		}
	} else if (item.children) {
		var k = 0
		item.children.forEach((child) => {
			child.urls = []
			recursion(child, item, k++)
		})
	}
	if (father) {
		father.urls = [...father.urls, ...item.urls]
	}
}

export default menus
